<template>
  <div>
    <img class="header" src="/img/mafff/header.jpg">
    <div class="main">
      <p>Welcome to the Movies &amp; Filmmaking Discord first annual Film Festival! The festival will run from <b>September 4th</b> through <b>September 18th, 2022</b>. All members of the MaF community can peruse through the titles below, and if you like what you see, click that entry's button to submit your vote! You have the full two weeks to decide which titles you vote for, and can vote for as many as you like. At the end of the festival, the votes will be tallied, and the winners will be announced shortly thereafter.</p>

      <p>Keep an eye on the Events panel on the Discord. For some of the entries, we have Ask Me Anything events with the filmmaker who submitted the short. We'll watch it together, and be able to ask them, well, anything! Also be on the lookout for special streams of blocks of shorts; if you'd rather watch them with others, join one of these.</p>

      <p>Use the navigation below to jump to those lengths of submissions. A big thanks to all of our contributors for their artistry, the MaF staff and ushers for their work organizing the supporting events, and an extra special thanks to our developer <b>Lucks</b> and admin <b>Aubses</b> for going above and beyond on the technical side. Enjoy the shows!</p>

      <nav>
        <span v-for="(category, idx) in categories" :key="category.id">
          <a :href="`#${category.id}`">{{ category.id }}</a>
          <span v-if="idx < categories.length-1"> | </span>
        </span>
      </nav>

      <div v-for="category in categories" :key="category.id">
        <h2 :id="category.id">{{ category.id + ' shorts' }}</h2>
        <div v-for="film in category.entries" :key="film.id" class="entry">
          <a :href="film.url" target="_blank"> <!-- FIXME -->
            <img :src="`${imageDir + film.id}.jpg`"> <!-- TODO -->
          </a>
          <span class="etitle">{{ film.title }}</span>
          <span class="elength">{{ formatDuration(film.length) }}</span>
          <span class="esubmitter">{{ film.submitter }}</span>
          <span class="erole">Role in project:
            <div v-for="role in film.role.split(' ')" :key="role">
              <span :class="['emblem', 'tooltip', role]">
                <span class="tooltiptext">
                  {{ formatRole(role) }}
                </span>
              </span>
            </div>
          </span>
          <button style="cursor:pointer;" @click="toggleVote(film)" :class="['likeit', hasVoted(film) ? 'already' : '']">
            {{ hasVoted(film) ? 'Voted!' : 'Vote for me!' }}
          </button>
        </div>
      </div>

    </div>
    </div>
</template>

<script>
import { getFilms, getUserVotes, updateUserVotes } from '@/api/mafff'
export default {
  data: () => ({
    films: [],
    userVotes: [],
    categories: [],
    imageDir: '/img/mafff/' // TODO
  }),
  async beforeMount () {
    await this.fetchFilms() // TODO

    await this.fetchUserVotes() // TODO

    this.categories = [
      {
        id: 'Long',
        entries: this.films.filter(f => (f.length > 600))
          .sort((a, b) => a.title.localeCompare(b.title, 'en'))
      },
      {
        id: 'Middle',
        entries: this.films.filter(f => (f.length > 300 && f.length <= 600))
          .sort((a, b) => a.title.localeCompare(b.title, 'en'))
      },
      {
        id: 'Short',
        entries: this.films.filter(f => (f.length <= 300))
          .sort((a, b) => a.title.localeCompare(b.title, 'en'))
      }
    ]
  },
  methods: {
    // API
    async fetchFilms () {
      this.films = (await getFilms()).films // TODO
    },
    async fetchUserVotes () {
      this.userVotes = (await getUserVotes()).votes
        .map(uv => uv.mafffId)
    },
    async toggleVote (film) {
      if (this.userVotes.includes(film.id)) {
        this.userVotes.splice(this.userVotes.indexOf(film.id), 1)
      } else {
        this.userVotes.push(film.id)
      }

      await updateUserVotes('@me', { votes: this.userVotes })
    },
    hasVoted (film) {
      if (!this.userVotes?.length) return false
      return this.userVotes.includes(film.id)
    },
    // Formatting
    formatRole (role) {
      const roles = [
        ['all', 'All'],
        ['dir', 'Director'],
        ['wri', 'Writer'],
        ['pro', 'Producer'],
        ['dp', 'DP'],
        ['edi', 'Editor'],
        ['ss', 'Script Supervisor'],
        ['sou', 'Sound']
      ]
      return roles.find(r => r[0] === role)[1]
    },

    formatDuration (duration) {
      const minutes = Math.floor(duration / 60)
      const seconds = duration % 60
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }
  }
}
</script>

<style>
/* MaFFF stylesheet */

/*** DESKTOP AND MID ***/

@media all and (min-width: 1028px) {
  div.main {
    border: 1px solid #666;
    box-shadow: 0px 0px 30px 5px rgba(20, 20, 20, 0.3);
  }
  img.header {
      margin: 0 auto;
  }
  .likeit {
      font-size: 1.2em;
  }
  .main nav {
      font-size: 1.8em;
      width: 400px;
      padding: 8px 16px;
  }
  .entry {
      margin: 0 15px 30px 15px;
  }
  .etitle {
    font-size: 1.4em;
  }

  h2 {
    font-size: 2.6em;
    padding: 0.8em 0 0.8em 1.6em;
  }

  .emblem {
    display: inline-block;
    height: 30px;
    width: 30px;
    margin-right: 2px;
    margin-bottom: -4px;
    background: no-repeat url('/img/mafff/emblems-vert.png');
    background-size: 100%;
  }

  .all { background-position: 0 0; }
  .dir { background-position: 0 -30px; }
  .wri { background-position: 0 -60px; }
  .pro { background-position: 0 -90px; }
  .dp  { background-position: 0 -120px; }
  .edi { background-position: 0 -150px; }
  .ss  { background-position: 0 -180px; }
  .sou { background-position: 0 -210px; }

}

/*** DESKTOP ***/
@media all and (min-width: 1550px) {

  div.main, img.header {
    width: 1549px;
  }

.main > p {
  font-size: 1.4em;
    margin: 15px 20%;
  }

  .esubmitter, .erole {
    font-size: 1.2em;
  }

}

/** Too small for DESKTOP, too large for PHONE **/
@media all and (max-width: 1549px) and (min-width: 1028px) {
  div.main, img.header {
    width: 1034px;
  }

  .main > p {
    font-size: 1.2em;
    margin: 15px 8%;
  }
  .esubmitter, .erole {
    font-size: 1.1em;
  }

}

/*** PHONE ***/
@media all and (max-width: 1027px) {
  div.main, img.header {
    width: 500px;
    max-width: 100%;
  }
    img.header {
    margin: 0 auto;
  }
  .esubmitter, .erole {
  font-size: 0.9em;
  }
  h2 {
      font-size: 2.3em;
      padding: 0.5em 0 0.5em 1.6em;
  }

  .main nav {
    font-size: 1.4em;
    width: 300px;
    padding: 0.3em 0.8em;
  }

  .entry {
    margin: 0 5px 30px 5px;
    max-width: 95%;
  }
  .etitle {
    font-size: 1.2em;
  }
  .main > p {
    font-size: 1em;
    margin: 15px 5%;
  }
  .likeit {
    font-size: 0.9em;
  }

  .emblem {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 2px;
    background: no-repeat url('/img/mafff/emblems-vert.png');
    background-size: 100%;
  }

  .all { background-position: 0 0; }
  .dir { background-position: 0 -20px; }
  .wri { background-position: 0 -40px; }
  .pro { background-position: 0 -60px; }
  .dp  { background-position: 0 -80px; }
  .edi { background-position: 0 -100px; }
  .ss  { background-position: 0 -120px; }
  .sou { background-position: 0 -140px; }

}

/* FIXME if this is scoped, can I do the * call like this */
* { margin: 0; padding: 0; }

#app { /* hello vue */
  font-family: "Calibri", "Dubai", "DejaVu Sans Condensed", sans-serif;
  color: #ffe;
  background-color: #2c2f33;
}

img.header { display: block; }

div.main {
  background-color: #23272a;
  margin: 0 auto 2em;
  padding: 15px 0 15px 3px;
}

.main nav {
  margin: 1em auto;
  border-radius: 15px;
  border: 1px solid #ffe;
  background-color: #33373a;
  text-align: center;
}

.main nav a {
    display: inline-block;
    text-decoration: none;
    padding: 3px 8px;
    font-weight: bold;
}

.main nav a:link, .main nav a:visited {
  color: #6ebec4;
}

.main nav a:hover, .main nav a:active {
  color: #7eced4;
}

h2 {
  font-weight: bold;
  margin: 25px 15px;
  border-left: 6px solid #6ebec4;

}

.entry {
  display: inline-block;

  /* border: 1px solid black; */
  padding: 2px;
}

.entry:hover {
  background-color: #33373a;
}

.entry a:link, .entry a:visited, .entry a:hover, .entry a:active {
  color: #000000;
  text-decoration: none;
}

.entry a img {
  display: block;
  max-width: 100%;
}

.entry > span {
  display: block;
  padding: 5px 8px;
}

.esubmitter, .erole {
  width: 69%;
}
.erole div {
  display: inline-block;
}
.erole div:first-child .emblem {
  margin-left: 0.5em;
}
.etitle {
  font-weight: bold;
  clear: none;
  width: 70%;
}

.elength {
  float: right;
  text-align: right;
  font-size: 1.3em;
  width: 18%;
  margin-top: -40px;
}

.likeit {
  width: 30%;
  background-color: #1b7d7a;
  float: right;
  color: #f8f8f8;
  font-weight: bold;
  padding: 8px;
  border: 3px solid #3b9d9a;
  border-radius: 30px;
  margin: -45px 0 5px 0;
}

button.already {
  background-color: #22af38;
  border: 3px solid #42cf58;
}

.tooltip {
  position: relative;
  top: 5px;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

</style>
