// import store from '@/store'
import request from '@/utils/request'

export const getFilms = async () => {
  return request({
    url: '/mafff',
    method: 'get'
  })
}

export const getUserVotes = async (user = '@me') => {
  return request({
    url: `/mafff/votes/${user}`,
    method: 'get'
  })
}

export const updateUserVotes = async (user = '@me', data) => {
  return request({
    url: `/mafff/votes/${user}`,
    method: 'put',
    data
  })
}
